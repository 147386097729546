import BaseBlockContent from '@sanity/block-content-to-react'
import React from 'react'
// import parse from 'html-react-parser';
import Figure from './figure'
import Slideshow from './slideshow'

import typography from '../typography.module.css'
import Video from '../video/video'

const serializersDefault = {
  types: {
    block (props) {
      const { node, children } = props
      switch (props.node.style) {
        case 'h1':
          return <h1>{props.children}</h1>

        case 'h2':
          return <h2>{props.children}</h2>

        case 'h3':
          return <h3>{props.children}</h3>

        case 'h4':
          return <h4>{props.children}</h4>

        case 'blockquote':
          return <blockquote>{props.children}</blockquote>

        default:
          if (children.length === 1 && typeof children[0] === 'string' && children[0].trim() === '') {
            return null
          } else {
            const processedChildren = React.Children.map(children, (child) => {
              if (typeof child === 'string') {
                return child.split(/(\[.*?\])/g).map((part, index) => {
                  if (part.startsWith('[') && part.endsWith(']')) {
                    return <span className='timeCode' key={index}>{part.replace('[', '').replace(']', '')}</span>
                  }
                  return part
                })
              }
              return child
            })
            return <p className={typography.paragraph}>{processedChildren}</p>
          }
      }
    },
    figure (props) {
      if (!props.node) return null
      return <Figure {...props.node} />
    },
    videoEmbed (props) {
      if (!props) return null
      return <p><Video videoId={props.node.videoId} addClass='writtenVideoEmbed' type={props.node.youtubePlayer ? 'youtube' : 'standard'} mute={1} /></p>
    },
    slideshow (props) {
      return <Slideshow {...props.node} />
    },
    embedCode (props) {
      if (props.node.code) {
        // return parse(props.node.code);
        return <div dangerouslySetInnerHTML={{ __html: props.node.code }} />
      }
      return <></>
    }
  },
  marks: {
    link: ({ mark, children }) => {
      // Read https://css-tricks.com/use-target_blank/
      const { blank, href } = mark
      return blank
        ? <a href={href} target='_blank' rel='noopener noreferrer'>{children}</a>
        : <a href={href}>{children}</a>
    }
  }
}

const BlockContent = ({ blocks, serializers }) => <BaseBlockContent blocks={blocks} serializers={serializers || serializersDefault} />

export default BlockContent
